import React, { useState } from "react";
import Header from "../../common/Header";
import { ReactComponent as RightArrow } from "../../../assets/RightArrow.svg";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { handleSendMessage } from "../../../scripts/handleSendMessage";
import { nanoid } from "nanoid";
import firebase from "../../../config/firebase";

dayjs.extend(localizedFormat);

export default function ReviewOrder({ selectedProducts, selectedDate, cost }) {
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  const { connectionId } = useParams();

  const db = firebase.firestore();

  console.log(selectedProducts);

  function submitOrder() {
    const order = nanoid();
    db.collection("connections")
      .doc(connectionId)
      .collection("orders")
      .doc(order)
      .set({
        type: "order",
        statusUpdates: [
          {
            text: "order confirmed",
            timestamp: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
          },
        ],
        products: selectedProducts,
        deliveryDate: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        deliveryInstructions:
          document.getElementById("instructions")?.value || "",
        created: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        id: order,
        sentBy: "buyer",
      })
      .then(() => history.push(`/message/${connectionId}`))
      .catch((error) => console.log(error));
  }

  return (
    <div className="white-background">
      <Header
        title="Australian Oyster Providores"
        subtitle="review your order"
      />
      <div className="light-gray table-header">
        {selectedProducts?.length} product, ${cost} est. cost
      </div>
      <Link
        to={`/place-order/select-products/${connectionId}`}
        className="review-order-row"
      >
        <div>
          <span className="page-title">Product</span>
          <br />
          <span className="subtitle">{`${selectedProducts[0]?.quantity} x ${selectedProducts[0]?.name}`}</span>
        </div>
        <RightArrow />
      </Link>
      <Link
        to={`/place-order/select-date/${connectionId}`}
        className="review-order-row"
      >
        <div>
          <span className="page-title">Delivery Date</span>
          <br />
          <span className="subtitle">{selectedDate?.format("LL")}</span>
        </div>
        <RightArrow />
      </Link>
      <Link
        to={`/place-order/delivery/${connectionId}`}
        className="review-order-row"
      >
        <div>
          <span className="page-title">Delivery Address</span>
          <br />
          <span className="subtitle">70 Albert St, Berry NSW 2535</span>
        </div>
        <RightArrow />
      </Link>
      {/* <div className="spaced">
        <span className="page-title">Instructions (optional)</span>
        <br />
        <br />
        <textarea
          placeholder="Enter instructions for supplier"
          className="input text-area"
          id="instructions"
        />
      </div> */}
      <div className="floor-container spaced">
        <button className="footer-button" onClick={() => submitOrder()}>
          {!submitting ? "Place Order" : "Loading"}
        </button>
      </div>
    </div>
  );
}
