import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Header from "../../common/Header";

function Product({ product }) {
  return (
    <div className="review-product">
      <div className="quantity-count">{product.quantity}</div>
      <div>
        <span className="title">{product.name}</span>
        <br />
        <span className="subtitle">
          ${product.price}/{product.unit}
        </span>
      </div>
    </div>
  );
}

export default function ({ selectedProducts, cost, setCost }) {
  const { connectionId } = useParams();
  useEffect(() => {
    // calculate price
    let x = 0;
    selectedProducts.forEach((product) => {
      x += Number(product.price) * Number(product.quantity);
      setCost(x);
    });
  }, []);

  return (
    <>
      <div className="white-background">
        <Header
          title="Australian Oyster Providores"
          subtitle="review products"
        />
        <div className="table-header" style={{ padding: "4px 16px" }}>
          <span>
            {selectedProducts.length} product, ${cost} est. cost
          </span>
        </div>
        {selectedProducts.map((selectedProduct) => (
          <Product key={selectedProduct.id} product={selectedProduct} />
        ))}
      </div>
      <Link to={`/place-order/select-date/${connectionId}`}>
        <div className="floor-container spaced">
          <button className="footer-button">Choose delivery date</button>
        </div>
      </Link>
    </>
  );
}
