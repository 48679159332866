import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import Header from "../../common/Header";

function Product({ product, selectedProducts, setSelectedProducts }) {
  const [count, setCount] = useState(null);
  let ran = false;
  function selected() {
    if (count && count > 0) {
      return "tertiary";
    } else return "secondary";
  }
  function handleValueChange(e) {
    e.preventDefault();
    const input = e.target.value;
    const re = /^[0-9\b]+$/;
    const selected = [...selectedProducts];
    if (input != 0 && re.test(input)) {
      setCount(input);
      const productOrder = { ...product };
      productOrder.quantity = input;
      let sel = selected.filter(
        (selectedProduct) => product?.id !== selectedProduct.id
      );
      sel.push(productOrder);
      setSelectedProducts(sel);
    } else {
      setSelectedProducts(
        selected.filter((selectedProduct) => product?.id !== selectedProduct.id)
      );
      setCount(null);
      e.target.value = null;
    }
  }

  useEffect(() => {
    if (!ran) {
      setCount(5);
      setSelectedProducts([{ ...product, quantity: "5" }]);
    }
  }, []);

  return (
    <div className="orderable-product">
      <span className>
        {product?.name} | {product?.grading?.join(", ")}
      </span>
      <span className="subtitle">
        ${product?.price}/{product?.unit}
      </span>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <input
          className="quantity-field"
          type="text"
          placeholder="--"
          min="0"
          pattern="[0-9]*"
          value={count}
          onChange={handleValueChange}
          onFocus={(e) => e.target.select()}
        />
        <div style={{ display: "flex", gap: 4 }}>
          <button
            className={`small-button ${selected()}`}
            onClick={() => setCount(count - 1)}
          >
            -
          </button>
          <button
            className={`small-button ${selected()}`}
            onClick={() => setCount(count - 0 + 1)}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
}

export default function SelectProducts({
  products,
  selectedProducts,
  setSelectedProducts,
}) {
  const history = useHistory();
  const [url, setUrl] = useState();
  const { connectionId } = useParams();
  useEffect(() => {
    setUrl(
      localStorage.getItem("redirect") ||
        `/place-order/review-products/${connectionId}`
    );
    localStorage.setItem("redirect", null);
  }, []);

  return (
    <>
      <Header title="Product list" subtitle="Add products" />
      <div className="order-table">
        <div className="order-table-header">
          <span>{`${selectedProducts?.length || 0} products added`}</span>
        </div>
        {products?.map((product) => (
          <Product
            product={product}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            key={product?.id}
          />
        ))}
      </div>
      <div className="floor-container spaced">
        <div className="footer-button-container">
          <button
            className="footer-button tertiary"
            onClick={() => history.push(`/message/${connectionId}`)}
          >
            Go back
          </button>
          {selectedProducts?.length > 0 && (
            <Link to={`/place-order/review-products/${connectionId}`}>
              <button className="footer-button">Continue</button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}
