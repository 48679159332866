import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PhoneField from "../common/PhoneField";
import StandardField from "../common/StandardField";
import validateUserInfo from "../../scripts/validateUserInfo";
import firebase from "../../config/firebase";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
} from "../../../node_modules/formik/dist/index";
import Header from "../common/Header";

export default function UserInfo() {
  const [initialValues, setInitalValues] = useState(null);
  const history = useHistory();
  const db = firebase.firestore();

  function handleLogOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("signed out");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (!initialValues) return <div className="white-background" />;

  return (
    <div className="app-container" style={{ backgroundColor: "white" }}>
      <Header
        style={{ padding: "0 0 24px 0" }}
        title="Your profile"
        subtitle="Used for all chats and sites you join"
      />

      <Formik
        initialValues={localStorage.getItem("userr")}
        validate={(values) => {
          const errors = validateUserInfo(values);
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            // Create user document
            history.push("/");
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <StandardField name="firstName" label="First name" />
            <StandardField name="lastName" label="Last name" />
            <StandardField type="email" name="email" label="Email address" />
            <PhoneField />
            <button
              className="footer-button"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </button>
            {/* <button onClick={() => handleLogOut()}>Sign out</button> */}
            <br /> <br />
          </Form>
        )}
      </Formik>
    </div>
  );
}
