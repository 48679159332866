import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import ClaimAcc from "./components/onboarding/ClaimAcc";
import Messaging from "./components/messaging/Messaging";
import Dashboard from "./components/dashboard/Dashboard";
import AllConnections from "./components/messaging/AllConnections";
import PlaceOrder from "./components/ordering/PlaceOrder";
import UserSettings from "./components/settings/UserSettings";
import VerifyPhone from "./components/onboarding/VerifyPhone";
import firebase from "./config/firebase";
import UserInfo from "./components/onboarding/UserInfo";
import Sandbox from "./components/common/Sandbox";

export default function App({ StoreContext }) {
  const [auth, setAuth] = useState(true);
  const db = firebase.firestore();
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      console.log("logged in");
      // console.log("user found", user.uid);
      // db.collection("users")
      //   .where("uid", "==", user.uid)
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       const data = doc.data();
      //       sessionStorage.setItem("user", data);
      //       console.log("userApp", sessionStorage.getItem("user"));
      //       console.log("buyers", data.buyerId);
      //       db.collection("buyers")
      //         .doc(data.buyerId)
      //         .get()
      //         .then((buyerDoc) => {
      //           console.log("buyer", buyerDoc);
      //           const buyer = buyerDoc.data();
      //           sessionStorage.setItem("buyer", buyer);
      //           console.log("buyerApp", sessionStorage.getItem("buyer"));
      //         });
      //     });
      //   })
      //   .catch((error) => {
      //     console.log("Error getting documents: ", error);
      //   });
      setAuth(true);
    } else {
      // setAuth(false);
    }
  });

  const AuthenticatedRoute = ({ children, ...rest }) => {
    // if (auth) {
    return <Route {...rest}>{children}</Route>;
    // }
    // else {
    //   return <Redirect to="/sign-in" />;
    // }
  };

  const DashboardRoute = ({ children, ...rest }) => (
    <AuthenticatedRoute {...rest}>
      <Dashboard>{children}</Dashboard>
    </AuthenticatedRoute>
  );

  return (
    <Router>
      <Switch>
        <Route path="/sandbox">
          <Sandbox StoreContext={StoreContext} />
        </Route>
        <Route path="/claim/:acc">
          <ClaimAcc />
        </Route>
        <Route path="/sign-up">
          <UserInfo />
        </Route>
        <Route path="/verify-phone">
          <VerifyPhone />
        </Route>
        <AuthenticatedRoute path="/message/:connectionId">
          <Messaging />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/place-order/:stage/:connectionId">
          <PlaceOrder />
        </AuthenticatedRoute>
        <DashboardRoute path="/user-settings">
          <UserSettings />
        </DashboardRoute>
        <DashboardRoute path="/">
          <AllConnections StoreContext={StoreContext} />
        </DashboardRoute>
      </Switch>
    </Router>
  );
}
