import React from "react";
import Tabs from "../common/Tabs";

export default function Dashboard({ children }) {
  return (
    <div style={{ alignItems: "baseline", marginBottom: "36px" }}>
      {children}
      <Tabs />
    </div>
  );
}
