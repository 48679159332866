import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React from "react";
import { ReactComponent as Check2 } from "../../assets/Check2.svg";

export default function Message({ message }) {
  console.log("message", message);
  // dayjs.extend(utc);
  // console.log("utc date", dayjs.utc().format);
  return (
    <div
      className={`message ${message.sentBy === "buyer" ? "sent" : "received"} ${
        message.type
      }`}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <b>
          {/* {message.sentBy === "buyer"
            ? "Tre Bambini"
            :  */}
          Australian Oyster Providores
          {/* } */}
        </b>
        <span>{dayjs(message.created.seconds).format("hh:mma")}</span>
      </div>
      <MessagePayload message={message} />
    </div>
  );
}

function MessagePayload({ message }) {
  switch (message.type) {
    // case "update":
    //   return <p>{message.text}</p>;
    case "order":
      return (
        <div style={{ position: "relative" }}>
          <span style={{ fontSize: "14px" }}>
            {message.statusUpdates[message.statusUpdates.length - 1].text}
          </span>
          <br />
          <br />
          <span>{dayjs(message.created.seconds).format("dddd, DD MMM")}</span>
          <br />
          <span className="subtitle">{message.id}</span>

          <div className={`order-message-icon accepted`}>
            <Check2 fill="white" />
          </div>
        </div>
      );
    default:
      return <span>{message.content}</span>;
  }
}
