import { ErrorMessage, Field } from "../../../node_modules/formik/dist/index";

const PhoneField = () => (
  <>
    <span className="label">Phone number</span>
    <div style={{ display: "flex", marginBottom: "24px" }}>
      <div
        style={{
          padding: "19px 12px 11px 12px",
          border: "1px solid lightgray",
          width: "min-content",
        }}
      >
        <span className="page-subtitle">+61</span>
      </div>
      <Field
        style={{ marginLeft: "4px", flexGrow: "1" }}
        className="phone"
        type="tel"
        name="phone"
        label="Phone number"
      />
      <ErrorMessage name="phone" component="div" />
    </div>
  </>
);
export default PhoneField;
