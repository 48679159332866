import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import weekday from "dayjs/plugin/weekday";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

export default function SelectDate({ setSelectedDate, selectedDate }) {
  const { connectionId } = useParams();
  dayjs.extend(weekday);

  function getValidDates() {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.25) {
          const x = dates.find(
            (date) => date.format("D") === entry.target.innerHTML
          );
          setSelectedDate(x);
        }
      });
    };

    const dateBubbles = [...document.querySelectorAll("li")];
    // Create an observer for every date bubble
    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px",
      threshold: 1,
    });
    dateBubbles.forEach((section) => {
      observer.observe(section);
    });
    // Scroll to default date
    console.log(document.querySelectorAll("li"));
    dateBubbles
      .find((bubble) => bubble.innerHTML === "2")
      .scrollIntoView({ inline: "nearest" });
  }

  // Load dates
  let dates = [];
  for (let i = 1; i < 16; i++) {
    dates.push(dayjs().weekday(i));
  }

  useEffect(() => {
    getValidDates();
  }, []);

  return (
    <div
      className="white-background"
      style={{
        textAlign: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ height: 24 }} />
      <span className="page-title" style={{ marginTop: "36px" }}>
        Delivery date
      </span>
      <br />
      <span className="page-subtitle">
        {selectedDate?.format("dddd D MMMM")}
      </span>
      <br />
      <div className="" style={{ paddingBottom: "36px" }}>
        <ul className="date-container" id="dates">
          <div></div>
          {dates.map((date, index) => (
            <li className={date.disabled && "disabled-date"}>
              {date.format("D")}
            </li>
          ))}
          <div></div>
        </ul>
        <span
          style={{
            backgroundColor: "lightgray",
            padding: "4px 12px",
            marginTop: "12px",
          }}
        >
          swipe to change date
        </span>
      </div>
      <div className="floor-container spaced">
        <Link to={`/place-order/review-order/${connectionId}`}>
          <button className="footer-button">Continue</button>
        </Link>
      </div>
    </div>
  );
}
