import React from "react";
import Header from "../common/Header";
import ReactCodeInput from "react-verification-code-input";
import { useHistory } from "react-router";
import firebase from "firebase";

export default function VerifyPhone() {
  const history = useHistory();
  const db = firebase.firestore();
  function handleSubmit(code) {
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        history.push("/");
        db.collection("users").where("uid", "==", result.user.uid);
      })
      .catch((error) => {
        // Error signing in
        console.log(error);
      });
  }
  return (
    <div className="white-background">
      <Header
        title="Enter the code"
        subtitle="We've sent a text message with a verification code"
      />
      <div className="code-container spaced">
        <p>Verification code</p>
        <ReactCodeInput
          className="verify-phone-code-container"
          onComplete={(code) => handleSubmit(code)}
        />
        <p className="subtitle">Please enter the code from your SMS</p>
      </div>
    </div>
  );
}
