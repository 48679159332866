import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PhoneField from "../common/PhoneField";
import StandardField from "../common/StandardField";
import validateUserInfo from "../../scripts/validateUserInfo";
import firebase from "../../config/firebase";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
} from "../../../node_modules/formik/dist/index";
import Header from "../common/Header";

export default function UserInfo() {
  const history = useHistory();

  const setUpRecaptcha = (onSignInSubmit) => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          console.log(response);
          onSignInSubmit();
        },
      }
    );
  };

  useEffect(() => {
    setUpRecaptcha();
  }, []);

  return (
    <div className="app-container" style={{ backgroundColor: "white" }}>
      <Header
        style={{ padding: "0 0 24px 0" }}
        title="Check your details"
        subtitle="These were added by Australian Oyster Providores"
      />

      <Formik
        initialValues={
          JSON.parse(sessionStorage.getItem("claimedUser")) || {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          }
        }
        validate={(values) => {
          const errors = validateUserInfo(values);
          if (!values.tosCheck) {
            errors.tosCheck = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            // Authenticate user
            const phoneNumber = "+61" + values.phone;
            const appVerifier = window.recaptchaVerifier;
            firebase
              .auth()
              .signInWithPhoneNumber(phoneNumber, appVerifier)
              .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                history.push("/verify-phone");
                setSubmitting(false);
              })
              .catch((error) => {
                console.log(error);
              });
            localStorage.setItem("userr", values);
            // Create user document
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <StandardField name="firstName" label="First name" />
            <StandardField name="lastName" label="Last name" />
            <StandardField type="email" name="email" label="Email address" />
            <PhoneField />
            <div style={{ display: "flex" }}>
              <label className="container">
                <Field type="checkbox" name="tosCheck" className="checkbox" />
                <span className="checkmark" />
              </label>
              <div>
                <span>I agree to:</span>
                <br />
                <span className="fine-print">
                  Terms and Conditions <br />
                  Privacy Policy <br />
                  Get contacted to verify account <br />
                </span>
                <ErrorMessage
                  name="tosCheck"
                  component="span"
                  className="error"
                />
              </div>
            </div>
            <div style={{ height: 24 }} />
            <button
              id="sign-in-button"
              className="footer-button"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Loading..." : "Sign up"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
