import dayjs from "dayjs";
import { nanoid } from "nanoid";
import firebase from "../config/firebase";

const db = firebase.firestore();
export function handleSendMessage(connection, message) {
  const newMessage = {
    created: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
    content: message,
    sentBy: "buyer",
    type: "message",
    id: nanoid(),
  };
  db.collection("connections")
    .doc(connection)
    .collection("orders")
    .doc(newMessage.id)
    .set(newMessage);
}
