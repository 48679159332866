import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { configure, makeAutoObservable, observable } from "mobx";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const rootEl = document.getElementById("root");

const stripePromise = loadStripe(
  // "pk_test_51IGCvSCosps4atHqhnq2zCzyDvQf8MlS7ELud7brmpzyfOKMplZ3MCbFT0ycYOsnVoLw0sA9l4zHYFnbX8bJAgTm00g5n0zxhF"
  "pk_live_51IGCvSCosps4atHqN0r5nuJEoWr0h8OpxkEgFpMXWQ7lB0hQyjKieG7MwnwurLABnE7H3dRRyxflczjFw3TGyjks00V78wzGGH"
  ,
  {
    stripeAccount: "acct_1IGCvSCosps4atHq",
  }
);

configure({
  enforceActions: "always",
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
  disableErrorBoundaries: true,
});

const StoreContext = React.createContext();

// const StoreProvider = ({ children }, value) => {
//   const store = makeAutoObservable(() => ({
//     user,
//     setUser: (user) => {
//       store.user = user;
//     },
//     buyer: observable,
//     setBuyer: (buyer) => {
//       store.buyer = buyer;
//     },
//   }));

//   return (
//     <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
//   );
// };

ReactDOM.render(
  // <StoreProvider>
  <Elements stripe={stripePromise}>
    <React.StrictMode>
      <App StoreContext={StoreContext} />
    </React.StrictMode>
  </Elements>,
  // </StoreProvider>
  rootEl
);

reportWebVitals();
