import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "../../config/firebase";

const Connection = ({ connection }) => (
  <Link to={`/message/${connection.id}`} className="connection panel">
    <div className="connection-icon">{connection.supplierName.charAt(0)}</div>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex" }}>
        <span className="connection-name">{connection.supplierName}</span>
        <span className="connection-timestamp">
          {dayjs(connection.lastInteraction).format("h:mma")}
        </span>
      </div>
      <span>{connection.lastMessage}</span>
    </div>
  </Link>
);

export default function AllConnections({ StoreContext }) {
  const [connections, setConnections] = useState([
    {
      buyerName: "Heads Bowlo",
      id: "3K44YFeFVK7ebxh7DI3Vv",
      lastInteraction:
        "Thu Jun 03 2021 12:31:27 GMT+1000 (Australian Eastern Standard Time)",
      lastMessage: "Ready to order",
      supplier: "6dhYPIt_q0UpnOfKP9ZTQ",
      supplierName: "Australian Oyster Providores",
    },
  ]);
  const db = firebase.firestore();

  useEffect(() => {
    // getData();
  }, []);

  async function getData() {
    setTimeout(() => {
      console.log("user", sessionStorage.getItem("user"));
      db.collection("connections")
        .where("buyerId", "==", sessionStorage.getItem("user").buyerId)
        .get()
        .then((docs) => {
          setConnections(docs.map((doc) => doc.data()));
        })
        .catch((err) => console.log(err));
    }, 10000);
  }

  return (
    <div className="app-container" style={{ alignItems: "baseline" }}>
      <header className="header">
        <span className="page-title">Orders</span>
        <br />
        <span className="page-subtitle">
          {connections?.length} supplier{connections?.length > 1 ? "s" : ""}
        </span>
      </header>
      {connections?.map((connection) => (
        <Connection key={connection.id} connection={connection} />
      ))}
    </div>
  );
}
