import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../../assets/LeftArrow.svg";
import { ReactComponent as Cog } from "../../assets/Cog.svg";
import { ReactComponent as Send } from "../../assets/Send.svg";
import Message from "./Message";
import { handleSendMessage } from "../../scripts/handleSendMessage";
import firebase from "../../config/firebase";
import { ReactComponent as RightArrow } from "../../assets/RightArrow.svg";

export default function Messaging() {
  const [message, setMessage] = useState();
  const [messages, setMessages] = useState(null);
  const db = firebase.firestore();
  const { connectionId } = useParams();

  useEffect(() => {
    watchMessages();
  }, []);

  async function watchMessages() {
    db.collection("connections")
      .doc(connectionId)
      .collection("orders")
      .onSnapshot((querySnapshot) => {
        var orders = [];
        querySnapshot.forEach((doc) => {
          orders.push(doc.data());
        });
        orders.sort((a, b) => a.created.seconds - b.created.seconds);
        console.log("orders after sort", orders);
        setMessages(orders);
      });
  }
  if (!messages) return <></>;

  return (
    <div>
      <div className="connection-header">
        <Link to="/orders">
          <button className="small-button">
            <LeftArrow />
          </button>
        </Link>
        <span className="text-overflow message-head">
          Australian Oyster Providores
        </span>
        <Link to="/user-settings">
          <button className="small-button">
            <Cog />
          </button>
        </Link>
      </div>
      <div className="messages-container">
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
        <div id="bottom" />
      </div>

      <div className="floor-container">
        <Link
          // onClick={() =>
          //   localStorage.setItem("redirect", "/place-order/review-order/x")
          // }
          to={`/place-order/select-products/${connectionId}`}
        >
          <button className="new-order">
            Start new order{" "}
            <RightArrow
              width={26}
              height={26}
              fill="white"
              style={{ paddingRight: 5 }}
            />
          </button>
        </Link>
        <input
          className="message-input"
          id="message-input"
          placeholder="Write message"
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          onClick={() => {
            if (message?.length > 0) {
              handleSendMessage(connectionId, message);
              setMessage(null);
              document.getElementById("message-input").value = "";
            }
          }}
          className="send-message-button small-button tertiary"
        >
          <Send width="16" height="16" />
        </button>
      </div>
      {() => document.getElementById("bottom").scrollIntoView()}
    </div>
  );
}
