import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import firebase from "../../../config/firebase";
import { useHistory, useParams } from "react-router-dom";
import Header from "../../common/Header";
import { ReactComponent as CreditCard } from "../../../assets/CreditCard2.svg";
import { ReactComponent as LeftArrow } from "../../../assets/LeftArrow.svg";
import { ReactComponent as Stripe } from "../../../assets/Stripe.svg";

export default function Payment({ cost }) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const { connectionId } = useParams();

  useEffect(() => {
    var createPaymentIntent = firebase
      .functions()
      .httpsCallable("createPaymentIntent");
    createPaymentIntent({ email: "lachlan.brooks@crestrock.com.au", cost })
      .then((result) => {
        console.log(result);
        setClientSecret(result.data.client_secret);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (succeeded === true) {
      history.push(`/message/${connectionId}`);
    }
  }, [succeeded]);

  const cardStyle = {
    style: {
      base: {
        color: "#141414",
        fontFamily: "Helvetica, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#808080",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
      setup_future_usage: "off_session",
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <div>
      <div
        style={{
          margin: "12px 0 0 12px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          className="small-button"
          onClick={() =>
            history.push(`/place-order/review-order/${connectionId}`)
          }
        >
          <LeftArrow />
        </button>
        <Stripe
          style={{
            margin: "6px 12px 0px 0",
          }}
        />
      </div>
      <Header
        // style={{ marginTop: 0, paddingTop: 12 }}
        title="payment method"
        subtitle="for your whole team to use"
      />
      <form
        id="payment-form"
        className="app-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px",
        }}
        onSubmit={handleSubmit}
      >
        <CreditCard width={240} />

        <div style={{ height: 24 }} />

        <CardElement
          id="card-element"
          className="input payment-input"
          options={cardStyle}
          onChange={handleChange}
        />
        {/* <div
          style={{
            textAlign: "left",
            backgroundColor: "gray",
            padding: "8px 20px",
            width: "-moz-available",
            minWidth: "-webkit-fill-available",
            borderRadius: "0 0 24px 24px",
            color: "white",
            fontWeight: 300,
          }}
        >
          
        </div> */}
        <p style={{ color: "#808080", textAlign: "center" }}>
          Your card details will be stored securely on Stripe according to
          Stripe's privacy policy.
        </p>
        <div style={{ height: 16 }} />
        <button
          className="footer-button"
          style={{ fontSize: 18 }}
          disabled={processing || disabled || succeeded}
          id="submit"
        >
          <span id="button-text">
            {processing ? "Processing Payment..." : `Tap to order`}
          </span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
      </form>
    </div>
  );
}
