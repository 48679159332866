import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import deployData from "../../scripts/db/deployData";
import getDoc from "../../scripts/db/getDoc";
import SelectSite from "../../SelectSite";
import ReviewOrder from "./components/ReviewOrder";
import ReviewProducts from "./components/ReviewProducts";
import SelectDate from "./components/SelectDate";
import SelectProducts from "./components/SelectProducts";
import Payment from "./components/Payment";

export default function PlaceOrder() {
  const [selectedProducts, setSelectedProducts] = useState([
    {
      name: "Sydney Rock Oysters",
      grading: ["Bistro", "Stick"],
      price: "15",
      unit: "Dozen",
      id: "zbhsaeioriuyaoglr",
      quantity: 5,
    },
  ]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [cost, setCost] = useState(0);
  const [products, setProducts] = useState([
    {
      name: "Sydney Rock Oysters",
      grading: ["Bistro", "Stick"],
      price: "15",
      unit: "Dozen",
      id: "zbhsaeioriuyaoglr",
    },
  ]);

  useEffect(() => {
    // getLastOrder();
    // getQuotes();
    // deployData();
  }, []);

  function getQuotes() {
    // Get supplier doc > products
    // save products in variable
    // Get quotes on products
    // Catch call support
  }

  function getLastOrder() {
    // Get doc of most recent order
    // Set state with defaults
    // Catch use default
  }

  const { stage } = useParams();

  return (
    <>
      {stage === "select-products" && (
        <SelectProducts
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          products={products}
        />
      )}

      {stage === "review-products" && (
        <ReviewProducts
          selectedProducts={selectedProducts}
          setCost={setCost}
          cost={cost}
        />
      )}

      {stage === "select-date" && (
        <SelectDate
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}

      {stage === "review-order" && (
        <ReviewOrder
          cost={cost}
          selectedDate={selectedDate}
          selectedProducts={selectedProducts}
        />
      )}

      {stage === "select-site" && (
        <SelectSite
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
      {stage === "payment" && <Payment cost={cost} />}
    </>
  );
}
