import { ErrorMessage, Field } from "../../../node_modules/formik/dist/index";

export default ({ name, label, ...rest }) => (
  <>
    <span className="label">{label || name}</span>
    <Field name={name} className="input" {...rest} />
    <ErrorMessage name={name} component="div" className="error" />
    <div style={{ height: "24px" }} />
  </>
);
