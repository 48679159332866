import React from "react";

export default function Header({ title, subtitle, ...rest }) {
  return (
    <div className="header-container" {...rest}>
      <span className="page-title">{title}</span>
      <br />
      <span className="page-subtitle">{subtitle}</span>
    </div>
  );
}
