import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as Check2 } from "../../assets/Check2.svg";
import AOPLogo from "../../assets/AOPLogo.png";
import firebase from "../../config/firebase";
import getDoc from "../../scripts/db/getDoc";

export default function ClaimAcc() {
  const { acc } = useParams();
  const db = firebase.firestore();

  async function getUserInfo() {
    const doc = await getDoc("users", acc);
    sessionStorage.setItem("claimedUser", null);
    doc.id = acc;
    localStorage.setItem("user", JSON.stringify(doc));
    doc.tosCheck = false;
    doc.claimed = undefined;
    sessionStorage.setItem("claimedUser", JSON.stringify(doc));
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="spaced center">
      <img
        src={AOPLogo}
        alt="AOP"
        style={{ width: "110px", height: "110px" }}
      />
      <h3 className="center header" style={{ fontSize: "22px" }}>
        Welcome to AOP, the free ordering platform for chefs
      </h3>

      <div className="panel left">
        <div className="invited-by-box">
          <span>You have been invited by:</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <div style={{ maxWidth: "80%" }}>
            <span className="supplier">Australian Oyster Providores</span>
            <br />
            <span className="subtitle">Oysters</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: "green",
                color: "white",
                borderRadius: "24px",
                padding: "8px 12px",
                height: "20px",
              }}
            >
              ✓
            </div>
          </div>
        </div>
      </div>
      <div className="floor-container spaced">
        <span className="fine-print left">
          The all-in-one platform for ordering
        </span>
        <Link to="/sign-up">
          <button className="footer-button">Get started</button>
        </Link>
      </div>
    </div>
  );
}
