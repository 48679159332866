import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Chat } from "../../assets/Chat.svg";
import { ReactComponent as User } from "../../assets/User.svg";

export default function Tabs() {
  const location = useLocation().pathname;

  const Tab = ({ label, link, icon }) => {
    return (
      <Link className="tab" to={link}>
        {icon}
        <span>{label}</span>
      </Link>
    );
  };
  return (
    <nav className="floor-container tabs-container">
      <Tab
        label="Orders"
        link="/orders"
        icon={
          <Chat
            fill={location === "/" || location === "/orders" ? "black" : "gray"}
          />
        }
      />
      <Tab
        label="Settings"
        link="/user-settings"
        icon={<User fill={location === "/user-settings" ? "black" : "gray"} />}
      />
    </nav>
  );
}
