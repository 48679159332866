import firebase from "../../config/firebase";

export default function getDoc(collection, doc) {
  const db = firebase.firestore();
  return new Promise((resolve) => {
    db.collection(collection)
      .doc(doc)
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          throw "Document does not exist";
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  });
}
