import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions"

var firebaseConfig = {
  apiKey: "AIzaSyALb77fcVLt43TSyf3JmsXV8mEx5zj6ugk",
  authDomain: "agripals.firebaseapp.com",
  projectId: "agripals",
  storageBucket: "agripals.appspot.com",
  messagingSenderId: "848993034726",
  appId: "1:848993034726:web:3d8c1bb665abe6f4787627",
  measurementId: "G-9EQQ1144ER",
};

firebase.initializeApp(firebaseConfig);
firebase.auth().languageCode = "en";
firebase.firestore();
firebase.functions()
// .useEmulator("localhost", 5001);

export default firebase;
