import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import StandardField from "./components/common/StandardField";
import firebase from "./config/firebase";
import validateUserInfo from "./scripts/validateUserInfo";

export default function SelectSite() {
  const history = useHistory();
  const { connectionId } = useParams();

  useEffect(() => {
    // Load deliveryaddress
  }, []);

  return (
    <div>
      <div style={{ height: "32px" }}></div>
      <Formik
        initialValues={{ firstName: "Lachlan" }}
        validate={(values) => {
          const errors = validateUserInfo(values);
          if (!values.tosCheck) {
            errors.tosCheck = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            // Authenticate user
            const phoneNumber = "+61" + values.phone;
            const appVerifier = window.recaptchaVerifier;
            firebase
              .auth()
              .signInWithPhoneNumber(phoneNumber, appVerifier)
              .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
              })
              .catch((error) => {
                console.log(error);
              });
            // Create user document
            history.push(`/place-order/review-order/${connectionId}`);
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <Field name="first" label="First name" className="" />
            <ErrorMessage name="" component="div" className="error" />
            <Field name="firstName" label="First name" />
            <ErrorMessage name="" component="div" className="error" />
            <Field name="firstName" label="First name" />
            <ErrorMessage name="" component="div" className="error" />
            <div style={{ height: 24 }} />
            <button
              id="sign-in-button"
              className="footer-button"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </button>
          </Form>
        )}
      </Formik>
      <div className="footer-container">
        <button className=""></button>
      </div>
    </div>
  );
}
